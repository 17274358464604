import React, { useEffect, useState } from 'react';
import { Alert, Collapse, Snackbar } from '@mui/material';
import './settings.scss';
import Header from '../../../organisms/header/header.js';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { jwtDecode } from 'jwt-decode';
import {Link, Navigate, useNavigate} from 'react-router-dom';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import decodeToken from "../../../../utils/decode-token";

function RetailerSettings() {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const decodedToken = jwtDecode(token);
  const userId = decodedToken.id;
  const role = decodeToken(token).role;
  const navigate = useNavigate();


  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [userLanguage, setUserLanguage] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token') || null;

        if (!token) {
          console.error('No se encontró ningún token en sessionStorage.');
          return;
        }

        const url = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/${userId}`;

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await apiClient.get(url, config);

        const { name, last_name, prefix_phone, phone, preferred_language } =
          response.data;

        setValue('name', name);
        setValue('lastName', last_name);
        setValue('prefixPhone', prefix_phone);
        setValue('phone', phone);
        setUserLanguage(preferred_language);
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('alert-server-error'), error);
        }
      }
    };

    fetchData();
  }, [setValue, userId, t]);

  const [expandedSections, setExpandedSections] = useState({
    language: false,
    contact: false,
    password: false,
  });

  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section],
    });
  };

  const languages = [
    { name: t('languages-de-AT'), code: 'de-AT' },
    { name: t('languages-de-DE'), code: 'de-DE' },
    { name: t('languages-nl-NL'), code: 'du' },
    { name: t('languages-en'), code: 'en' },
    { name: t('languages-es'), code: 'es' },
    { name: t('languages-fr-FR'), code: 'fr-FR' },
    { name: t('languages-fr-BE'), code: 'fr-BE' },
    { name: t('languages-it'), code: 'it' },
    { name: t('languages-pt'), code: 'pt' },
    { name: t('languages-sk'), code: 'sk' },
    { name: t('languages-tr'), code: 'tr' },
    { name: t('languages-cz'), code: 'cz' },
  ];

  const [openSnackbarLanguages, setOpenSnackbarLanguages] = useState(false);
  const [openSnackbarContact, setOpenSnackbarContact] = useState(false);

  const handleCloseSnackbarLanguages = () => {
    setOpenSnackbarLanguages(false);
  };
  const handleCloseSnackbarContact = () => {
    setOpenSnackbarContact(false);
  };

  const saveLanguage = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/personal-settings?salePersonId=${userId}`;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const data = {
        language: userLanguage,
      };

      await apiClient.patch(url, data, config);
      i18n.changeLanguage(userLanguage);
      setSnackbarMessage(t('settings-setting1-feedback'));
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      setSnackbarMessage(t('settings-setting1-error'));
    }

    setOpenSnackbarLanguages(true);
  };

  const saveContactInfo = async (data) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/personal-settings?salePersonId=${userId}`;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { name, lastName, prefixPhone, phone } = data;

      await apiClient.patch(
        url,
        { name, last_name: lastName, prefix_phone: prefixPhone, phone },
        config
      );
      setSnackbarMessage(t('settings-setting2-feedback'));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      setSnackbarMessage(t('settings-setting2-error'));
    }

    setOpenSnackbarContact(true);
  };

  return (
    <>
      {
      (token && (role === 'retailer' || role === 'retailerGM')) ? (
        <div className="App">
        <Header />
        <main>
          <section className="settings">
            <div className="inner">
              <Link to="/retailer/main/" className="back-button">
                <span className="icon-chevron-left"></span>{' '}
                {t('common-back')}
              </Link>
              <h2>{t('settings-title')}</h2>
              <p>{t('settings-text')}</p>

              {/* contact */}
              <div className="title" onClick={() => toggleSection('contact')}>
                <h5>{t('settings-setting2')}</h5>
                <button className="btn text">
                  {expandedSections.contact ? (
                    <span className="icon-minus"></span>
                  ) : (
                    <span className="icon-plus"></span>
                  )}
                </button>
              </div>
              <Collapse in={expandedSections.contact}>
                <form onSubmit={handleSubmit(saveContactInfo)}>
                  <Grid container spacing={1}>
                    <Grid xs={12} sm={6} md={5}>
                      <div
                        className={`form-control required ${
                          errors.name ? 'error' : ''
                        }`}
                      >
                        <label htmlFor="name">{t('common-first-name')}</label>
                        <input
                          maxLength="50"
                          type="text"
                          id="name"
                          name="name"
                          autoComplete="off"
                          {...register('name', {
                            required: t('alert-name'),
                          })}
                        />
                        {errors.name && <span>{errors.name.message}</span>}
                      </div>
                    </Grid>
                    <Grid xs={12} sm={6} md={5}>
                      <div
                        className={`form-control required ${
                          errors.lastName ? 'error' : ''
                        }`}
                      >
                        <label htmlFor="lastName">{t('common-last-name')}</label>
                        <input
                          maxLength="50"
                          type="text"
                          id="lastName"
                          name="lastName"
                          autoComplete="off"
                          {...register('lastName', {
                            required: t('alert-last-name'),
                          })}
                        />
                        {errors.lastName && (
                          <span>{errors.lastName.message}</span>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <div
                    className={`form-control ${
                      errors.prefixPhone ? 'error' : ''
                    }`}
                  >
                    <Grid container spacing={1}>
                      <Grid xs={4} sm={4} md={4}>
                        <div
                          className={`form-control required ${
                            errors.prefixPhone ? 'error' : ''
                          }`}
                        >
                          <label htmlFor="prefix_phone">
                            {t('common-prefix')}
                          </label>
                          <input
                            type="text"
                            id="prefix_phone"
                            name="prefixPhone"
                            autoComplete="off"
                            placeholder="+xx"
                            {...register('prefixPhone', {
                              required: t('alert-prefix'),
                              pattern: {
                                value: /^\+[0-9]{2}$/,
                                message: 'Prefix format +xx',
                              },
                            })}
                          />
                          {errors.prefixPhone && (
                            <span>{errors.prefixPhone.message}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid xs={8} sm={8} md={8}>
                        <div
                          className={`form-control required ${
                            errors.phone ? 'error' : ''
                          }`}
                        >
                          <label htmlFor="phone">{t('common-phone')}</label>
                          <input
                            type="text"
                            id="phone"
                            name="phone"
                            autoComplete="off"
                            placeholder="xxxxxxxxx"
                            {...register('phone', {
                              required: t('alert-phone'),
                              pattern: {
                                value: /^\d{9}$/, // Expresión regular para nueve números
                                message: 'Phone must be 9 digits long',
                              },
                            })}
                          />
                          {errors.phone && <span>{errors.phone.message}</span>}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="actions">
                    <button className="btn" type="submit">
                      {t('common-save-changes')}
                    </button>
                  </div>
                </form>
              </Collapse>

              {/* language */}
              <div className="title" onClick={() => toggleSection('language')}>
                <h5>{t('settings-setting1')}</h5>
                <button className="btn text">
                  {expandedSections.language ? (
                    <span className="icon-minus"></span>
                  ) : (
                    <span className="icon-plus"></span>
                  )}
                </button>
              </div>
              <Collapse in={expandedSections.language}>
                <div className="form-control">
                  <select
                    name="area_code"
                    id="country"
                    className="normal"
                    value={userLanguage}
                    onChange={(e) => setUserLanguage(e.target.value)}
                  >
                    {languages.map((language) => (
                      <option key={language.code} value={language.code}>
                        {language.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="actions">
                  <button className="btn" onClick={saveLanguage}>
                    {t('common-save-changes')}
                  </button>
                </div>
              </Collapse>

              {/* resetear password */}
              <div className="title" onClick={() => toggleSection('password')}>
                <h5>{t('settings-setting4')}</h5>
                <button className="btn text">
                  {expandedSections.password ? (
                    <span className="icon-minus"></span>
                  ) : (
                    <span className="icon-plus"></span>
                  )}
                </button>
              </div>
              <Collapse in={expandedSections.password}>
                <p>{t('settings-setting4-text')}</p>
                <div className="actions">
                  <Link to="/password-recovery" className="btn">
                    {t('common-change-password')}
                  </Link>
                </div>
              </Collapse>
            </div>
          </section>
        </main>
        <Snackbar
          open={openSnackbarLanguages}
          autoHideDuration={3000}
          onClose={handleCloseSnackbarLanguages}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackbarContact}
          autoHideDuration={3000}
          onClose={handleCloseSnackbarContact}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default RetailerSettings;
